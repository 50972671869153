/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "whitespace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#whitespace",
    "aria-label": "whitespace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Whitespace"), "\n", React.createElement(_components.p, null, "Utilities for controlling an element's white-space property."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "whiteSpace={value}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "white-space: {value};"))))), "\n", React.createElement(_components.h2, {
    id: "normal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#normal",
    "aria-label": "normal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Normal"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "whiteSpace=\"normal\""), " to cause text to wrap normally within an element. Newlines and spaces will be collapsed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=indigo"
  }, "<>\n  <template preview>\n    <x.div w={3 / 4} px={6} py={4} bg=\"indigo-200\" borderRadius=\"lg\">\n      <x.div\n        whiteSpace=\"normal\"\n        fontFamily=\"Flow\"\n        color=\"indigo-500\"\n        fontSize=\"3xl\"\n        lineHeight={5}\n      >\n        {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n      </x.div>\n    </x.div>\n  </template>\n  <x.div w={3 / 4}>\n    <x.div whiteSpace=\"normal\">\n      {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n    </x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "no-wrap",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-wrap",
    "aria-label": "no wrap permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No Wrap"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "whiteSpace=\"nowrap\""), " to prevent text from wrapping within an element. Newlines and spaces will be collapsed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div\n      w={3 / 4}\n      px={6}\n      py={4}\n      bg=\"emerald-200\"\n      borderRadius=\"lg\"\n      overflowX=\"auto\"\n    >\n      <x.div\n        whiteSpace=\"nowrap\"\n        fontFamily=\"Flow\"\n        color=\"emerald-500\"\n        fontSize=\"3xl\"\n        lineHeight={5}\n      >\n        {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n      </x.div>\n    </x.div>\n  </template>\n  <x.div w={3 / 4} overflowX=\"auto\">\n    <x.div whiteSpace=\"nowrap\">\n      {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n    </x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "pre",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pre",
    "aria-label": "pre permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pre"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "whiteSpace=\"pre\""), " to preserve newlines and spaces within an element. Text will not be wrapped."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div\n      w={3 / 4}\n      px={6}\n      py={4}\n      bg=\"amber-200\"\n      borderRadius=\"lg\"\n      overflowX=\"auto\"\n    >\n      <x.div\n        whiteSpace=\"pre\"\n        fontFamily=\"Flow\"\n        color=\"amber-500\"\n        fontSize=\"3xl\"\n        lineHeight={5}\n      >\n        {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n      </x.div>\n    </x.div>\n  </template>\n  <x.div w={3 / 4} overflowX=\"auto\">\n    <x.div whiteSpace=\"pre\">\n      {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n    </x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "pre-line",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pre-line",
    "aria-label": "pre line permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pre Line"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "whiteSpace=\"pre-line\""), " to preserve newlines but not spaces within an element. Text will be wrapped normally."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div\n      w={3 / 4}\n      px={6}\n      py={4}\n      bg=\"light-blue-200\"\n      borderRadius=\"lg\"\n      overflowX=\"auto\"\n    >\n      <x.div\n        whiteSpace=\"pre-line\"\n        fontFamily=\"Flow\"\n        color=\"light-blue-500\"\n        fontSize=\"3xl\"\n        lineHeight={5}\n      >\n        {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n      </x.div>\n    </x.div>\n  </template>\n  <x.div w={3 / 4} overflowX=\"auto\">\n    <x.div whiteSpace=\"pre-line\">\n      {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n    </x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "pre-wrap",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pre-wrap",
    "aria-label": "pre wrap permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pre Wrap"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "whiteSpace=\"pre-wrap\""), " to preserve newlines and spaces within an element. Text will be wrapped normally."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=red"
  }, "<>\n  <template preview>\n    <x.div\n      w={3 / 4}\n      px={6}\n      py={4}\n      bg=\"red-200\"\n      borderRadius=\"lg\"\n      overflowX=\"auto\"\n    >\n      <x.div\n        whiteSpace=\"pre-wrap\"\n        fontFamily=\"Flow\"\n        color=\"red-500\"\n        fontSize=\"3xl\"\n        lineHeight={5}\n      >\n        {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n      </x.div>\n    </x.div>\n  </template>\n  <x.div w={3 / 4} overflowX=\"auto\">\n    <x.div whiteSpace=\"pre-wrap\">\n      {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure,\n      eum natus enim maxime\n      laudantium quibusdam illo nihil,\n\n  reprehenderit saepe quam aliquid odio accusamus.`}\n    </x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the whitespace property of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "whiteSpace={{ md: \"pre\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "whiteSpace=\"pre\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div whiteSpace={{ md: 'pre' }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
